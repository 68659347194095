<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>
      Support
    </template>
    <template v-slot:default>
      <v-card max-width="344" outlined style="display: inline-block">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              iRC Handleiding
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn text @click="ircGuide">
            Downloaden
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        outlined
        style="display: inline-block; margin-left: 1rem"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              DigiPortal Gebruikers Handleiding
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn text @click="digiPortalUserGuide">
            Downloaden
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        outlined
        style="display: inline-block; margin-left: 1rem"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              DigiPortal Beheerders Handleiding
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn text @click="digiPortalCustomerGuide">
            Downloaden
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-divider class="my-4"></v-divider>
      <p class="display-1">Video tutorials</p>
      <v-card max-width="344" outlined style="display: inline-block">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              Deel 1: Registratie
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn text @click="ircVideoUrl = 'https://player.vimeo.com/progressive_redirect/playback/915878164/rendition/1080p/file.mp4?loc=external&signature=c34a53cb8bcf014068df7a88ba8d4970089f771764eb0c104367c6a8568cd308'">
            Bekijken
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
          outlined
          style="display: inline-block; margin-left: 1rem"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              Deel 2: Installatie iRC
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn text @click="ircVideoUrl = 'https://player.vimeo.com/progressive_redirect/playback/915875314/rendition/1080p/file.mp4?loc=external&signature=a6b5aa5adc2edb0b0929f0c502933d07e313cd0a1b59411bfa460a6ccfbe63e8'">
            Bekijken
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
          outlined
          style="display: inline-block; margin-left: 1rem"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              Deel 3: Mogelijkheden op gebruikersniveau
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn text @click="ircVideoUrl = 'https://player.vimeo.com/progressive_redirect/playback/915875166/rendition/1080p/file.mp4?loc=external&signature=953bb8abf5c4a65fe9cec1a967857be3c84837a48ed92e3000807260f2414cbc'">
            Bekijken
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
          outlined
          style="display: inline-block; margin-left: 1rem"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              Deel 4: Mogelijkheden op beheerdersniveau
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn text @click="ircVideoUrl = 'https://player.vimeo.com/progressive_redirect/playback/915875017/rendition/1080p/file.mp4?loc=external&signature=018661316a080310565398ae7eb0c4e177019af00d56679a00e017b4694fc53d'">
            Bekijken
          </v-btn>
        </v-card-actions>
      </v-card>
      <video-player-dialog v-model="ircVideoUrl" />
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import VideoPlayerDialog from "../../components/layout/VideoPlayerDialog.vue";

export default {
  name: "Support",
  components: {VideoPlayerDialog},
  data() {
    return {
      dashboardButtons: {
        ircManualLoading: false,
        digiPortalManualLoading: false
      },
      ircVideoUrl: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    contentLoaded() {
      return this.user !== undefined;
    }
  },
  methods: {
    ircGuide() {
      this.dashboardButtons.ircManualLoading = true;
      RepositoryFactory.get("ircProduct").downloadManual()
        .then(res => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "iRC Handleiding.pdf");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.dashboardButtons.ircManualLoading = false;
        });
    },

    digiPortalUserGuide() {
      this.dashboardButtons.digiPortalManualLoading = true;
      RepositoryFactory.get("support")
        .downloadUserManual()
        .then(res => {
          let blob = new Blob([res.data], {
            type: "application/pdf"
          });
          const link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "DigiPortal Handleiding.pdf");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.dashboardButtons.digiPortalManualLoading = false;
        });
    },
    digiPortalCustomerGuide() {
      this.dashboardButtons.digiPortalManualLoading = true;
      RepositoryFactory.get("support")
        .downloadCustomerManual()
        .then(res => {
          let blob = new Blob([res.data], {
            type: "application/pdf"
          });
          const link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "DigiPortal Handleiding.pdf");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.dashboardButtons.digiPortalManualLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
