<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title class="headline">Instructie Video</v-card-title>
      <v-card-text>
        <video
            ref="videoPlayer"
            controls
            style="width: 100%; height: auto;"
        >
          <source :src="videoUrl" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="closeDialog">Sluiten</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: 'videoUrl',
    event: 'input'
  },
  props: {
    videoUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.videoPlayer.load();
        this.$refs.videoPlayer.play();
      });
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('input', "");
      this.$nextTick(() => {
        this.$refs.videoPlayer.load();
      });
    }
  },
  watch: {
    videoUrl(val) {
      if (val) {
        this.openDialog();
      }
    },
    dialog(val, oldVal) {
      if (!val && val !== oldVal) {
        this.closeDialog();
      }
    }
  }
};
</script>

<style scoped></style>